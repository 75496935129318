.my-nft {
  min-height: 100vh;
  position: relative;
  background  : linear-gradient(180deg, #9796F0 0%, #FBC7D4 100%);
  background-attachment: fixed;
  overflow: hidden;
}

.my-nft .text-line {
  position: fixed;
}

.my-nft .text-line.left {
  left: 5px;
}

.my-nft .text-line.right {
  right: 5px;
}

.back {
  position: absolute;
  left: 26px;
  top: 5px;
}

.title-bar {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.title-bar .page-title {
  position: relative;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  top: 2px;
}

.my-nft .grid-container {
  width: 333px;
  min-height: 626px;
  margin: 6px auto 0;
  background-image: url("../assets/mynft/background.png");
  background-size: 100%;
  background-attachment: fixed;
  border-radius: 20px;
  padding: 59px 20px 200px;
}

.my-nft .contract {
  text-align: center;
  font-size: 14px;
  color: #1DD7D0;
  margin: 21px 0 33px;
}

.my-nft .contract .address {
  color: white;
}

.my-nft .card-container {
  color: #ffffff;
  padding-bottom: 20px;
}

.my-nft .nft-card {
  width: 111px;
  height: 159px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-nft img.nft {
  width: 125px;
}

.my-nft .card-title {
  width: 131px;
  font-size: 14px;
  margin: -13px 0 10px;
}

.my-nft .card-title .name {
  line-height: 20px;
}

.my-nft .card-title .address {
  line-height: 17px;
}

.my-nft .main {
  overflow: hidden;
}

.my-nft .notice-container {
  position: absolute;
  bottom: 46px;
  left: -10px;
}

.my-nft .notice {
  width: 360px;
  color: #FFFFFF;
  opacity: 0.8;
  size: 12px;
  transform: scale(0.75);
}

.my-nft .notice-img {
  position: absolute;
  bottom: 15px;
  left: 26px;
}