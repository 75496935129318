.login .title-container {
  margin: 0 0 20px;
}

.login .title-container .title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}

.login .title-container .subtitle {
  font-size: 12px;
  line-height: 12px;
  margin-top: 3px;
}

button.login.code {
  width: 87px;
  height: 42px;
  background: #F1F1F1;
  border-radius: 6px;
  margin-left: 10px;
}

.login .agreement-container {
  margin-top: 4px;
}

.login .agreement-container .MuiCheckbox-root{
  position: relative;
  top: -1px;
  margin-right: 10px;
}

.login .agreement-label {
  font-size: 12px;
  line-height: 17px;
}

button:disabled.login.next {
  color: #8E8E90;
  background: #F1F1F1;
}

button.login.next {
  width: 318px;
  height: 42px;
  margin-top: 27px;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  background: #F9914F;
  border-radius: 6px;
}

#cancel {
  background: transparent;
  color: #aa8964;
  filter: drop-shadow(0px 1px 1px rgba(172, 139, 103, 0.4));
  border: 1px solid;
  border-radius: 4px;
}

.login .validate {
  color: red;
  font-size: 12px;
  line-height: 12px;
  height: 12px;
  position: relative;
  transform: scale(0.83, 0.83);
}

.login .phone .validate {
  left: -22px;
}

.login .code .validate {
  left: -12px;
}

.login input {
  min-width: 0;
  height: 41.97px;
  padding: 0 10px;
  font-size: 16px;
  line-height: 16px;
  color: #050505;
  background: #F1F1F1;
  border-radius: 6px;
  box-sizing: border-box;
}

.login .close-button {
  position: absolute;
  right: -20px;
  top: -20px;
}

input::placeholder{
  color: #8E8E90;
}