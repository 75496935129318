.agreement {
  background-size: contain;
  background-repeat: no-repeat;
  padding: 0 13px;
}

.agreement .about-title {
  position: relative;
  font-size: 16px;
  font-weight: 600;
}

.agreement .agreement-content {
  height: 524px;
}

.agreement p {
  margin-bottom: 0.5rem;
  word-break: break-all;
}