.about {
  min-height: 100vh;
  background: linear-gradient(180deg, #9796F0 0%, #FBC7D4 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow: hidden;
}

.about .main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 333px;
  margin: auto;
  overflow: hidden;
}

.about .about-img {
  margin: 12px 0;
}

.about .content-container {
  background: url("../assets/about/background.png");
  background-size: cover;
  height: 626px;
}

.about .content {
  padding: 10px 30px;
  overflow: scroll;
  word-break: break-all;
}

.about .main .headline {
  margin-bottom: 16px;
}

.about p {
  color: #000000;
  line-height: 25px;
  text-align: justify;
  margin-bottom: 18px;
}