.home {
  background: linear-gradient(180deg, #9796F0 0%, #FBC7D4 100%);
  background-size: cover;
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

.home .text-line {
  position: absolute;
}

.home .text-line.left {
  left: 5px;
}

.home .text-line.right {
  right: 5px;
}

.home .main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home .main .headline {
  margin-bottom: 16px;
}

.home .nft-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../assets/home//nft-container-background.png");
  background-size: cover;
  width: 333px;
  height: 626px;
  border-radius: 20px;
  margin-bottom: 20px;
}


.home .tape {
  position: absolute;
  right: -74.9px;
  top: -69px;
  width: calc(316px/2);
  height: calc(276px/2);
}

.home .nft-image {
  position: relative;
  width: 183px;
  height: 287px;
  margin-left: 12px;
  margin-top: 60px;
}

.home .title1 {
  width: 284px;
  height: 20px;
  margin: 17px 0 5px;
}

.home .title2 {
  width: 296px;
  height: 27px;
}

.home .button-container {
  margin-top: 38px;
}

.home .button-claim {
  position: relative;
  width: 154px;
  height: 34px;
  align-items: center;
  background: #F9914F;
  border: 2px solid #000000;
  box-sizing: border-box;
  color: #020202;
  font-weight: 500;
  z-index: 1;
}

.home .button-shadow {
  position: absolute;
  left: 6px;
  top: 6px;
  width: 154px;
  height: 34px;
  border: 1px dashed #FFFFFF;
  box-sizing: border-box;
}

.home .get-more {
  height: 14px;
  margin-top: 13px;
  font-size: 12px;
  line-height: 14px;
  text-decoration-line: underline;
  color: #FFFFFF;
}

.home .notice {
  position: relative;
  top: -3px;
  width: 346.66px;
  font-size: 12px;
  font-weight: 300;
  transform: scale(0.75);
  color: #FFFFFF;
  opacity: 0.8;
  text-align: justify;
  text-align-last: left;
}

.login-container {
  height: 330px;
  padding: 28px 28px;
  background: #FFFFFF;
  background-size: cover;
}