div.claim {
  width: 322px;
  height: 253px;
  background-image: url("../assets/claim/claim-background.png");
  background-size: 100% 100%;
  position: relative;
  top: -74px;
}

.claim .title {
  padding: 33px 0 0 29px;
  font-size: 14px;
  line-height: 19px;
}

.claim .subtitle {
  padding: 24px 0 0 42px;
  font-size: 18px;
  font-weight: 600;
  line-height: 19px;
}

.claim .content {
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  mix-blend-mode: normal;
}

.claim .content .input-tip {
  color: #FFFFFF;
  opacity: 0.64;
  margin: 10px 0;
}

.claim .content input {
  width: 243px;
  height: 41px;
  margin-top: 32px;
  border: 1px solid #000000;
  background: transparent;
  padding-left: 10px;
  font-size: 16px;
}

.claim .control {
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.claim .control button.confirm {
  width: 133px;
  margin-top: 84px;
  color: #FFFFFF;
  background: #F9914F;
  border-radius: 6px;
  height: 33px;
}

.claim .control button.confirm:disabled {
  background: #3B3B3D;
}

.claim .control button.close {
  margin-top: 60px;
}

.loading {
  position: relative;
  top: -80px;
  width: 285px;
  height: 159px;
  background-image: url("../assets/claim/loading-background.svg");
  display: flex;
  justify-content: center;
}

.loading-img{
  width: 144px;
  height: 129px;
}

.loading-content {
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #FFFFFF;
}

.fail {
  width: 285px;
  height: 159px;
  background-image: url("../assets/claim/loading-background.svg");
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -74px
}

.fail .fail-img {
  margin-top: 24px;
}

.fail .content {
  font-size: 12px;
  color: #FFFFFF;
  margin-top: 19px;
}

.fail .content span {
  color: #FF5249;
}

.fail .control {
  position: relative;
  display: flex;
  justify-items: center;
  margin-top: 60px;
}

.fail button.re-input {
  width: 104px;
  height: 33px;
  color: #FFFFFF;
  width: 104px;
  height: 33px;
  background: #FF5249;
  border-radius: 6px;
}

.fail button.close {
  margin-top: 60px;
}

.fail button.try-now {
  width: 104px;
  height: 33px;
  font-size: 12px;
  color: #FFFFFF;
  background: #FF5249;
  line-height: 17px;
  border-radius: 6px 0px 0px 6px;;
}

.fail button.try-later {
  width: 104px;
  height: 33px;
  font-size: 12px;
  background: transparent;
  color: #FFFFFF;
  border: 1px solid #FF5149;
  box-sizing: border-box;
  border-radius: 0px 6px 6px 0px;
}

.success {
  width: 375px;
  background-size: 100% 100%;
  position: relative;
  top: -35px;
}

.success .nft-container {
  width: 375px;
  height: 502.54px;
  background-image: url("../assets/claim/success-background.svg");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success img.nft {
  width: 231px;
  height: 351px;
}

.success .congrats-container {
  text-align: center;
  line-height: 22px;
  font-size: 12px;
  transform: scale(0.83);
  color: #FFFFFF;
  width: 360px;
  margin: -40px auto 0;
}

.success img.qrcode {
  margin: 20px auto 0;
}

.success .control {
  color:#ffffff;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.success button.share {
  width: 104px;
  height: 33px;
  background: #F9914F;
  border-radius: 6px 0px 0px 6px;
}

.success button.to-detail {
  width: 104px;
  height: 33px;
  border: 1px solid #F9914F;
  box-sizing: border-box;
  border-radius: 0px 6px 6px 0px;
}

.success button.close {
  position: relative;
  margin: 32px 0 0 178px;
}