.detail {
  color: #ffffff;
  min-height: 100vh;
  background: linear-gradient(180deg, #9796F0 0%, #FBC7D4 100%);
  position: relative;
  padding-bottom: 48px;
  overflow: hidden;
}

.detail .text-line {
  position: absolute;
}

.detail .text-line.left {
  left: 5px;
}

.detail .text-line.right {
  right: 5px;
}

.detail .detail-container {
  width: 333px;
  height: 713px;
  margin: auto;
  background-image: url("../assets/detail/background.png");
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.nft-video {
  width: 163px;
  height: 255px;
  margin: 48px auto;
}

.detail .nft-container {
  background-size: cover;
}

.detail .nft-border {
  width: 324px;
  height: 428px;
  margin: 14.5px auto 0;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.detail .nft-information {
  position: relative;
  top: 24px;
  flex-basis: 349px;
  margin: 0px 13px 8px;
  padding: 7px 22px 0;
  box-sizing: border-box;
}

.detail .information-title {
  color:#1DD7D0;
  font-size: 18px;
  line-height: 25.2px;
  display: flex;
  justify-content: center;
}

.detail .information-content {
  margin-top: 9px;
  font-size: 14px;
}

.detail .information-item {
  display: flex;
  flex-basis: 307px;
  justify-content: space-between;
  margin: 15.5px 0 12px;
}

.detail .information-item .label {
  position: relative;
  color: #ffffff;
  font-size: 12px;
  transform: scale(0.75);
  transform-origin: 0 0;
}

.detail .information-item .value {
  position: relative;
  width: 180px;
  text-align: right;
  color: #ffffff;
  font-size: 12px;
  transform: scale(0.75);
  transform-origin: 178px 0;
}

.detail .notice-container {
  position: absolute;
  bottom: 46px;
  width: 100%;
  margin-top: 24px;
}

.detail .notice {
  width: 360px;
  margin: auto;
  font-size: 12px;
  transform: scale(0.75);
  transform-origin: 132px 60px;
}

.detail .poster-generate {
  position: absolute;
  bottom: 125px;
  left: 90px;
  width: 161.23px;
  height: 32.61px;
}

.detail .poster-generate .button-generate {
  position: relative;
  width: 154px;
  height: 34px;
  align-items: center;
  background: #F9914F;
  color: #020202;
  font-weight: 500;
  z-index: 1;
}

.detail .poster-generate .button-shadow {
  position: absolute;
  left: 6px;
  top: 6px;
  width: 154px;
  height: 34px;
  border: 1px dashed #FFFFFF;
  box-sizing: border-box;
}